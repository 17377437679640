@if (shipmentDetails !== 'hidden') {
  <div class="d-flex flex-row align-items-center justify-content-between p-3">
    <x-shipment-pill class="mr-auto" [shipment]="shipment"></x-shipment-pill>
    <button
      *ngIf="!readonly"
      mat-icon-button
      [matMenuTriggerFor]="shipmentMenu"
      [disabled]="!editable && shipment.state === 'NEW'"
    >
      <fa-icon icon="cog"></fa-icon>
    </button>
  </div>

  <mat-divider></mat-divider>
  <xd-expand-item icon="info-circle" [isExpanded]="expandedSections.includes('info')">
    <span xdHeadline>Info</span>
    <table class="mat-typography cart-summary-table data-table mb-3">
      <tbody>
        <tr>
          <th>Shipment ID</th>
          <td>{{ shipment.id }}</td>
        </tr>
        <tr>
          <th>Shipping Method</th>
          <td>
            <a [routerLink]="['/admin/logistics/shipping-methods/detail', shipment.method?.id]">
              {{ shipment.method?.name ?? 'Unassigned' }}
              @if (shipment.method) {
                [{{ shipment.method.id }}]
              }
            </a>
          </td>
        </tr>
        <tr>
          <th>Shipping State</th>
          <td>{{ 'ShipmentState.' + shipment.state | l }}</td>
        </tr>
        <tr>
          <th>Description</th>
          <td>{{ shipment.description ?? '-' }}</td>
        </tr>
        <tr>
          <th>Reference</th>
          <td>{{ shipment.reference ?? '-' }}</td>
        </tr>
        <tr>
          <th>Requested Slot</th>
          <td>
            @if (shipment.requestedSlotId) {
              <span>
                {{ [shipment.requestedSlotStartAt, shipment.requestedSlotEndAt] | dateRange }}
                <br />
                {{ shipment.requestedSlotId }}
              </span>
            } @else {
              -
            }
          </td>
        </tr>
        <tr>
          <th>Reserved Slot</th>
          <td>
            {{ shipment.assignedSlotId ?? '-' }}
          </td>
        </tr>
        <tr *ngIf="shipment.slot as slot">
          <th>Designated Slot</th>
          <td>
            {{ slot.id }}
            <br />
            {{ ([slot.startAt, slot.endAt] | dateRange: 'long') ?? '-' }}
          </td>
        </tr>
        <tr *ngIf="shipment.driver">
          <th>Driver</th>
          <td>
            {{ shipment.driver }}
          </td>
        </tr>
        <tr>
          <th>Total Weight</th>
          <td>{{ shipment.totalWeight }}g</td>
        </tr>
        <tr>
          <th>Labelled At</th>
          <td>{{ (shipment.labeledAt | date: 'short') ?? '-' }}</td>
        </tr>
        <tr>
          <th>Shipped At</th>
          <td>{{ (shipment.shippedAt | date: 'short') ?? '-' }}</td>
        </tr>
        <tr>
          <th>Completed At</th>
          <td>{{ (shipment.completedAt | date: 'short') ?? '-' }}</td>
        </tr>
        <tr>
          <th>Created At</th>
          <td>{{ (shipment.createdAt | date: 'short') ?? '-' }}</td>
        </tr>
        <tr>
          <th>Updated At</th>
          <td>{{ (shipment.updatedAt | date: 'short') ?? '-' }}</td>
        </tr>
      </tbody>
    </table>
    <div class="mb-3 d-flex justify-content-between align-items-center">
      <b>Shipping Address</b>
      <x-address-pill [address]="shipment.order.shippingAddress"></x-address-pill>
    </div>
  </xd-expand-item>
  <mat-divider></mat-divider>
}

@if (showOrderInfo) {
  <div class="my-3 d-flex justify-content-between align-items-center">
    <b>Shipment Order</b>
    <x-order-pill [order]="shipment.order" [matMenuTriggerFor]="orderMenu"></x-order-pill>
    <mat-menu #orderMenu>
      <a mat-menu-item [routerLink]="['/admin/orders', shipment.order.id, 'detail']">View</a>
    </mat-menu>
  </div>
  <mat-divider></mat-divider>
}

@if (unitDetails !== 'hidden') {
  <!-- UNIT DETAILS -->
  <xd-expand-item icon="shelves" [isExpanded]="expandedSections.includes('units')">
    <span xdHeadline>Units</span>
    @if (shipment.units && shipment.units.length !== 0) {
      <table class="mat-typography units-table">
        <tbody>
          <tr *ngFor="let unit of shipment.units">
            <td style="text-align: left">
              <a
                class="d-flex flex-column"
                *ngIf="unit.item.variant.stockItem"
                [routerLink]="[
                  '/admin/inventory/stock-items/detail',
                  unit.item.variant.stockItem.id,
                ]"
              >
                <b>
                  {{ unit.item.productName }}
                  {{ unit.item.variantName ? ' - ' + unit.item.variantName : '' }}
                </b>
                {{ unit.item.variant.stockItem.sku }}
              </a>
            </td>
            <td>
              <x-pill>{{ 'ShipmentUnitState.' + unit.state | l }}</x-pill>
            </td>
            <td>{{ unit.quantity }}</td>
          </tr>
        </tbody>
      </table>
    } @else {
      <div class="mat-label">No units</div>
    }
  </xd-expand-item>
  <mat-divider></mat-divider>
}

@if (parcelDetails !== 'hidden') {
  <!-- PARCEL DETAILS -->
  <xd-expand-item icon="box-open" [isExpanded]="expandedSections.includes('parcels')">
    <span xdHeadline>Parcels</span>
    @if (shipment.parcels && shipment.parcels.length !== 0) {
      @for (parcel of shipment.parcels; track $index) {
        <xd-list-item>
          <span xdHeadline>{{ parcel.name }}</span>
          <span xdLine *ngIf="parcel.reference">{{ parcel.reference }}</span>
          <span xdLine>
            {{
              (parcel.dimensions?.length ?? '-') +
                ' x ' +
                (parcel.dimensions?.height ?? '-') +
                ' x ' +
                (parcel.dimensions?.width ?? '-')
            }}
          </span>
          <span xdLine>{{ parcel.weight }}g</span>
          <button mat-icon-button (click)="editParcel.emit(parcel)" matTooltip="Edit">
            <fa-icon icon="pen"></fa-icon>
          </button>
          <button
            mat-icon-button
            (click)="removeParcel.emit(parcel)"
            matTooltip="Remove"
            color="warn"
          >
            <fa-icon icon="trash"></fa-icon>
          </button>
        </xd-list-item>
      }
    } @else {
      <div class="mat-label">No parcels found</div>
    }
    <x-action-row *ngIf="!readonly">
      <button
        mat-stroked-button
        color="primary"
        (click)="viewParcel.emit(shipment.id)"
        [disabled]="!['NEW', 'RESERVED', 'WAYBILL_REQUIRED'].includes(shipment.state)"
      >
        Add Parcel
      </button>
    </x-action-row>
  </xd-expand-item>
}

<mat-menu #shipmentMenu="matMenu">
  <button
    mat-menu-item
    [disabled]="!(shipment.state === 'NEW' && shipment.type === 'RETURN' && editable)"
    (click)="editReturn.emit(shipment)"
  >
    {{ editLabel }}
  </button>
  <button
    mat-menu-item
    [disabled]="!shipment.trackingUrl"
    (click)="openInNewTab(shipment.trackingUrl ?? '')"
  >
    Track
  </button>
  <button
    mat-menu-item
    [disabled]="!['WAYBILL_REQUIRED', 'PENDING', 'IN_TRANSIT'].includes(shipment.state)"
    (click)="recon.emit(shipment.id)"
  >
    Recon
  </button>
  <button
    mat-menu-item
    [disabled]="shipment.state !== 'WAYBILL_REQUIRED'"
    (click)="submitWaybill.emit(shipment.id)"
  >
    Submit Waybill
  </button>
  <button mat-menu-item [disabled]="shipment.state !== 'PENDING'" (click)="ship.emit(shipment.id)">
    Ship
  </button>
  <button
    mat-menu-item
    [disabled]="shipment.state !== 'IN_TRANSIT'"
    (click)="deliver.emit(shipment.id)"
  >
    Deliver
  </button>
  <button
    mat-menu-item
    [disabled]="!['WAYBILL_REQUIRED', 'PENDING'].includes(shipment.state)"
    (click)="cancel.emit(shipment.id)"
  >
    Cancel
  </button>
  <button
    mat-menu-item
    [disabled]="!['IN_TRANSIT'].includes(shipment.state)"
    (click)="fail.emit(shipment.id)"
  >
    Fail
  </button>
  <button
    mat-menu-item
    [disabled]="!['CANCELLED'].includes(shipment.state)"
    (click)="reschedule.emit(shipment.id)"
  >
    Reschedule
  </button>
  <button
    mat-menu-item
    [disabled]="!['FAILED'].includes(shipment.state)"
    (click)="recreate.emit(shipment.id)"
  >
    Recreate
  </button>
</mat-menu>
