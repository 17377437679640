<h2 mat-dialog-title>Return Shipment</h2>
<form [formGroup]="formGroup" mat-dialog-content>
  <x-dashboard-form-layout>
    <x-form-panel>
      <mat-form-field>
        <mat-label>Shipping Method</mat-label>
        <x-data-select
          formControlName="methodId"
          [provider]="Providers.ShippingMethodItemCollectionProvider"
          [page]="{ index: 0, size: 100 }"
          (selectionChange)="methodChanged()"
        ></x-data-select>
      </mat-form-field>
      <mat-form-field *ngIf="slotRequired">
        <mat-label>Shipping Slot</mat-label>
        <x-data-select
          formControlName="slotId"
          [provider]="Providers.ShippingSlotItemCollectionProvider"
          [filter]="{
            methodId: methodId,
            startAtRange: {
              type: 'relative',
              unit: 'day',
              endOffset: 90,
              expand: true,
            },
          }"
          [sort]="{ column: 'startAt', order: 'asc' }"
        ></x-data-select>
        <button type="button" mat-icon-button matSuffix (click)="selectFulfilmentInterval()">
          <fa-icon icon="calendar"></fa-icon>
        </button>
      </mat-form-field>
    </x-form-panel>
    <x-form-panel [class.uneditable]="!(data.options?.canEditUnits ?? true)">
      @if (orderView.fetchLoading) {
        <x-cube-spinner></x-cube-spinner>
      } @else {
        <div class="refund-lines">
          @for (item of orderView.data?.items; track $index) {
            <div
              class="refund-line"
              *ngIf="getOrCreateUnitGroup(item) as group"
              [formGroup]="group"
            >
              <div class="mat-typography">
                <b>{{ item.productName }}</b>
                <div style="font-weight: normal">{{ item.stockItem?.sku }}</div>
              </div>
              @if (data.options?.canEditUnits ?? true) {
                <x-cart-quantity-control
                  class="ms-auto"
                  formControlName="quantity"
                  [debounceTime]="100"
                  [min]="0"
                  [max]="item.qtyReturnable"
                ></x-cart-quantity-control>
              } @else {
                <x-pill class="my-3">{{ group.value.quantity }}</x-pill>
              }
            </div>
          }
        </div>
      }
    </x-form-panel>
  </x-dashboard-form-layout>
</form>
<div mat-dialog-actions align="end">
  <button mat-button mat-dialog-close (click)="cancel()">Cancel</button>
  <button
    mat-flat-button
    color="accent"
    (click)="submit('save')"
    [disabled]="formGroup.invalid || orderView.fetchLoading"
  >
    Save
  </button>
  <button
    *ngIf="data.options?.canSubmit ?? true"
    mat-flat-button
    color="primary"
    (click)="submit('submit')"
    [disabled]="formGroup.invalid || orderView.fetchLoading"
  >
    Save and Submit
  </button>
</div>
