import { Component, EventEmitter, Input, Output } from '@angular/core';
import { WindowRef } from '@x/common/browser';
import { ShipmentObject } from '@x/ecommerce/domain-client';

export type InfoPanelDisplayMode = 'visible' | 'hidden';

@Component({
  selector: 'x-shipment-info-box',
  templateUrl: './shipment-info-box.component.html',
  styleUrl: './shipment-info-box.component.scss',
  styles: `
    :host {
      display: block;
      margin-bottom: 1rem;
      border: 1px solid rgb(224, 233, 243);
    }
  `,
})
export class ShipmentInfoBoxComponent {
  @Input({ required: true }) shipment!: ShipmentObject;
  @Input() readonly = false;
  @Input() editable = true;
  @Input() showOrderInfo = false;

  @Input() shipmentDetails: InfoPanelDisplayMode = 'visible';
  @Input() unitDetails: InfoPanelDisplayMode = 'visible';
  @Input() parcelDetails: InfoPanelDisplayMode = 'visible';

  @Input() expandedSections: ('info' | 'units' | 'parcels')[] = [];

  @Input() editLabel = 'Edit';

  @Output() viewParcel = new EventEmitter<number>();
  @Output() removeParcel = new EventEmitter<unknown>();
  @Output() editParcel = new EventEmitter<unknown>();

  @Output() editReturn = new EventEmitter<ShipmentObject>();
  @Output() recon = new EventEmitter<number>();
  @Output() submitWaybill = new EventEmitter<number>();
  @Output() ship = new EventEmitter<number>();
  @Output() deliver = new EventEmitter<number>();
  @Output() cancel = new EventEmitter<number>();
  @Output() fail = new EventEmitter<number>();
  @Output() reschedule = new EventEmitter<number>();
  @Output() recreate = new EventEmitter<number>();

  constructor(private readonly window: WindowRef) {}

  openInNewTab(url: string) {
    this.window.openNewTab(url);
  }
}
