import { LanguageDeclaration } from '@x/common/locale';
import { ShipmentState } from '@x/schemas/ecommerce';

export const LOGISTICS_LANGUAGE: LanguageDeclaration = {
  shipment: {
    state: {
      NEW: 'New',
      WAYBILL_REQUIRED: 'Waybill Required',
      PENDING: 'Pending',
      IN_TRANSIT: 'Shipped',
      DELIVERED: 'Delivered',
      CANCELLED: 'Cancelled',
      RESERVED: 'Reserved',
      FAILED: 'Failed',
    } satisfies { [key in ShipmentState]: string },
    actions: {
      ship: 'Ship',
    },
  },
};
