<x-data-table [view]="view">
  <ng-template xDataColumnDef="id" let-row>
    {{ row.id }}
  </ng-template>

  <x-shipment-pill *xDataColumnDef="'reference'; let row" [shipment]="row"></x-shipment-pill>

  <x-channel-pill
    *xDataColumnDef="'channel'; let row"
    [channel]="row.order.channel"
  ></x-channel-pill>

  <x-address-pill
    *xDataColumnDef="'shippingAddress'; let row"
    [address]="row.order.shippingAddress"
    [region]="row.order.shippingAddressGeoRegion"
    [matMenuTriggerFor]="rowShippingAddressMenu"
    [matMenuTriggerData]="{ $implicit: row }"
  ></x-address-pill>

  <mat-menu #rowShippingAddressMenu>
    <ng-template matMenuContent let-shipment>
      <a mat-menu-item (click)="updateShippingAddress(shipment)">Update Shipping Address</a>
    </ng-template>
  </mat-menu>

  <x-geo-region-pill
    *xDataColumnDef="'shippingAddressGeoRegion'; let row"
    [region]="row.order.shippingAddressGeoRegion"
  ></x-geo-region-pill>

  <x-pill *xDataColumnDef="'collectionPoint'; let row">
    {{ row.order.collectionPoint?.name }}
  </x-pill>

  <ng-template xDataColumnDef="checkoutAt" let-row>
    <x-datetime [datetime]="row.order.checkoutAt" format="medium"></x-datetime>
  </ng-template>

  <ng-template xDataColumnDef="description" let-row>
    {{ row.description ?? '' }}
  </ng-template>

  <ng-template xDataColumnDef="errorDescription" let-row>
    {{ row.errorDescription }}
  </ng-template>

  <x-order-pill
    *xDataColumnDef="'order'; let row"
    [order]="row.order"
    [matMenuTriggerFor]="rowOrderMenu"
    [matMenuTriggerData]="{ $implicit: row.order }"
  ></x-order-pill>

  <mat-menu #rowOrderMenu>
    <ng-template matMenuContent let-order>
      <a mat-menu-item [routerLink]="['/admin/orders', order.id, 'detail']">View Order</a>
    </ng-template>
  </mat-menu>

  <ng-template xDataColumnDef="slot" let-row>
    @if (row.slot) {
      {{ row.slot.startAt | datetime: 'medium' }}
    }
  </ng-template>

  <ng-container *xDataColumnDef="'parcels'; let row">
    <div *ngIf="row.parcels.length > 0; else noStateCounts">
      <ng-container *ngFor="let parcel of row.parcels">
        <x-pill
          [matTooltip]="getParcelInfo(parcel)"
          [matMenuTriggerFor]="parcelMenu"
          [matMenuTriggerData]="{ $implicit: { row, parcel } }"
        >
          {{ parcel.name }}
        </x-pill>
      </ng-container>
    </div>
    <ng-template #noStateCounts></ng-template>
  </ng-container>

  <mat-menu #parcelMenu>
    <ng-template matMenuContent let-shipment>
      <a mat-menu-item (click)="editParcel(shipment)">Update Parcel</a>
    </ng-template>
  </mat-menu>

  <ng-template xDataColumnDef="note" let-row>
    {{ row.note ?? '' }}
  </ng-template>

  <ng-template xDataColumnDef="trackingUrl" let-row>
    <a *ngIf="row.trackingUrl" [href]="row.trackingUrl" target="_blank">
      <x-pill text="Track Shipment" color="yellow"></x-pill>
    </a>
  </ng-template>

  <ng-container *xDataColumnDef="'shipmentDetails'; let row">
    @if (row.driver) {
      <x-pill color="accent">
        <fa-icon icon="id-card-clip"></fa-icon>
        {{ row.driver }}&nbsp;/&nbsp;{{ row.routeSequence }}
      </x-pill>
    }
  </ng-container>

  <span *xDataColumnDef="'timestamps'; let row" class="mat-body-2">
    <x-timestamp-column [row]="row"></x-timestamp-column>
  </span>

  <!-- ACTIONS -->
  <button
    x-data-button
    *xDataAction="'track'; let row"
    label="Track"
    (click)="trackShipment(row.trackingUrl)"
    [disabled]="!row.trackingUrl"
  ></button>

  <button
    x-data-button
    *xDataAction="'recon'; let row"
    label="Recon"
    (click)="reconShipment(row.id)"
    [disabled]="!['WAYBILL_REQUIRED', 'PENDING', 'IN_TRANSIT'].includes(row.state)"
  ></button>

  <button
    x-data-button
    *xDataAction="'submitWaybill'; let row"
    label="Submit Waybill"
    (click)="submitWaybill(row.id)"
    [disabled]="row.state !== 'WAYBILL_REQUIRED'"
  ></button>

  <button
    x-data-button
    *xDataAction="'ship'; let row"
    label="Ship"
    (click)="shipShipment(row.id)"
    [disabled]="row.state !== 'PENDING'"
  ></button>

  <button
    x-data-button
    *xDataAction="'deliver'; let row"
    label="Delivery"
    (click)="deliverShipment(row.id)"
    [disabled]="row.state !== 'IN_TRANSIT'"
  ></button>

  <button
    x-data-button
    *xDataAction="'cancel'; let row"
    label="Cancel"
    (click)="cancelShipment(row.id)"
    [disabled]="!['WAYBILL_REQUIRED', 'PENDING'].includes(row.state)"
  ></button>

  <button
    x-data-button
    *xDataAction="'fail'; let row"
    label="Fail"
    (click)="failShipment(row.id)"
    [disabled]="!['IN_TRANSIT'].includes(row.state)"
  ></button>

  <button
    x-data-button
    *xDataAction="'addParcel'; let row"
    label="Add Parcel"
    (click)="openParcelDialog(row.id)"
    [disabled]="!(row.state === 'NEW' || row.state === 'WAYBILL_REQUIRED')"
  ></button>

  <!-- BULK ACTIONS -->
  <button
    x-data-button
    *xDataBulkAction="'shipment'; let row"
    label="Shipments"
    icon="truck"
    color="primary"
    [matMenuTriggerFor]="shipmentMenu"
  ></button>

  <mat-menu #shipmentMenu>
    <button mat-menu-item (click)="submitWaybillBulk()">Submit Waybill</button>
    <button mat-menu-item (click)="shipShipmentBulk()">Ship</button>
    <button mat-menu-item (click)="deliverShipmentBulk()">Deliver</button>
    <button mat-menu-item (click)="reconShipmentsBulk()">Recon Shipment</button>
    <button mat-menu-item (click)="rescheduleBulk()">Reschedule</button>
    <button mat-menu-item (click)="updateShipmentSlots()">Assign Shipping Slot</button>
    <button mat-menu-item (click)="cancelShipmentBulk()">Cancel Shipment</button>
  </mat-menu>

  <button
    x-data-button
    *xDataBulkAction="'shipment'; let row"
    label="Print"
    icon="truck"
    color="primary"
    [matMenuTriggerFor]="printMenu"
  ></button>

  <mat-menu #printMenu>
    <button mat-menu-item (click)="printManifestBulk()">Order Manifest</button>
    <button mat-menu-item (click)="printManifestV2Bulk()">Order Manifest V2</button>
    <button mat-menu-item (click)="printShipmentLabelsBulk()">Shipment Labels</button>
    <button mat-menu-item (click)="printRouteManifestBulk()">Route Manifest</button>
    <button mat-menu-item (click)="printRouteSummaryBulk()">Route Summary</button>
    <button mat-menu-item (click)="printShipmentOrderGiftMessageBulk()">Gift Messages</button>
  </mat-menu>

  <button
    x-data-button
    *xDataBulkAction="'shipment'; let row"
    label="Export"
    icon="file-arrow-down"
    color="primary"
    [matMenuTriggerFor]="exportMenu"
    [matMenuTriggerData]="{ $implicit: row }"
  ></button>

  <mat-menu #exportMenu>
    <ng-template matMenuContent let-row>
      <button mat-menu-item (click)="exportCourierSpreadsheet('csv')">DPD Export [.csv]</button>
      <button mat-menu-item (click)="exportCourierSpreadsheet('xlsx')">DPD Export [.xlsx]</button>
      <button mat-menu-item (click)="manifestBulk()">Courier-Manifest - Own Delivery [.csv]</button>
      <button mat-menu-item (click)="packFleetBulk('csv')">Packfleet Export [.csv]</button>
      <button mat-menu-item (click)="royalMailBulk(row, 'csv')">Royal Mail Export [.csv]</button>
      <button mat-menu-item>CSV-Manifest - Own Delivery [.csv]</button>
    </ng-template>
  </mat-menu>
</x-data-table>
