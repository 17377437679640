import { coerceArray } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WindowRef } from '@x/common/browser';
import { DataTableView } from '@x/common/data';
import { AnyOperationState, Operation } from '@x/common/operation';
import { PromptDialogService } from '@x/dashboard/dialog';
import { AddressDialogService } from '@x/ecommerce-admin/app/core/services/address-dialog.service';
import { OrderDialogService } from '@x/ecommerce-admin/app/orders/services/order-dialog.service';
import { OrderService, ShipmentRowObject, ShipmentService } from '@x/ecommerce/domain-client';
import { StockCategoryItemCollectionProvider } from '@x/ecommerce/domain-data';
import {
  AddressAssignment,
  ShipmentFilterInput,
  ShipmentState,
  ShipmentStateTransition,
} from '@x/schemas/ecommerce';
import { ShipmentRowFragment } from 'libs/ecommerce/src/domain-client/services/gql/shipment.gql.generated';
import { Observable, firstValueFrom, map } from 'rxjs';
import { ShipmentDialogService } from '../../services/shipment-dialog.service';
import { ShipmentFormDialogData } from '../shipment-form-dialog/shipment-form-dialog.component';

@Component({
  selector: 'x-shipment-table',
  templateUrl: './shipment-table.component.html',
  styleUrls: ['./shipment-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'x-shipment-table',
  },
})
export class ShipmentTableComponent implements OnInit {
  @Input()
  view: DataTableView<ShipmentRowObject, ShipmentFilterInput, any, number>;

  constructor(
    private changeRef: ChangeDetectorRef,
    private prompts: PromptDialogService,
    private orderService: OrderService,
    private shipmentService: ShipmentService,
    private orderDialogService: OrderDialogService,
    private snackbar: MatSnackBar,
    private shipmentDialogService: ShipmentDialogService,
    private windowRef: WindowRef,
    private promptDialogService: PromptDialogService,
    private readonly addressDialogService: AddressDialogService,
  ) {}

  ngOnInit(): void {
    this.view.stateChanges().subscribe(() => this.changeRef.markForCheck());
  }

  // Actions //

  cancelShipment(shipmentId: number) {
    this.processShipment(
      shipmentId,
      (id) => {
        return this.shipmentService.transitionShipment({
          id,
          transition: ShipmentStateTransition.Cancel,
          sendNotification: false,
        });
      },
      'Cancelled Shipment',
    ).subscribe();
  }

  failShipment(shipmentId: number) {
    this.processShipment(
      shipmentId,
      (id) => {
        return this.shipmentService.transitionShipment({
          id,
          transition: ShipmentStateTransition.Fail,
          sendNotification: false,
        });
      },
      'Fail Shipment',
    ).subscribe();
  }

  async reconShipment(shipmentId: number) {
    const shipment: ShipmentRowFragment | null = await firstValueFrom(this.view.item(shipmentId));

    if (!shipment) return;

    const readyForRecon =
      shipment.state === ShipmentState.Pending || shipment.state === ShipmentState.InTransit;

    if (!readyForRecon) {
      this.snackbar.open(`Shipment must be in Pending or Shipped state`, 'OK', {
        duration: 5000,
      });
      return;
    }

    const result = await firstValueFrom(
      this.prompts.confirm({
        title: 'Recon Shipment',
        message: `Are you sure you want to recon the shipment?`,
      }),
    );
    if (!result) return;

    return this.processShipment(
      shipmentId,
      (id) =>
        this.shipmentService.reconShipment({
          id,
          sendNotification: false,
        }),
      'Reconcile Shipment',
    ).subscribe();
  }

  submitWaybill(shipmentId: number) {
    this.processShipment(
      shipmentId,
      (id) =>
        this.shipmentService.transitionShipment({
          id,
          transition: ShipmentStateTransition.Ready,
          sendNotification: false,
        }),
      'Submit Shipment Waybill',
    ).subscribe();
  }

  async shipShipment(shipmentId: number) {
    const result = await firstValueFrom(this.orderDialogService.openOrderShipConfirmation());
    if (!result) return;

    this.processShipment(
      shipmentId,
      (id) =>
        this.shipmentService.transitionShipment({
          id,
          transition: ShipmentStateTransition.Ship,
        }),
      'Ship Shipment',
    ).subscribe();
  }

  async deliverShipment(shipmentId: number) {
    const result = await firstValueFrom(this.orderDialogService.openOrderDeliverConfirmation());
    if (!result) return;

    return this.processShipment(
      shipmentId,
      (id) =>
        this.shipmentService.transitionShipment({
          id,
          transition: ShipmentStateTransition.Deliver,
          sendNotification: false,
        }),
      'Deliver Shipment',
    ).subscribe();
  }

  trackShipment(trackUrl: string) {
    if (!trackUrl) {
      this.snackbar.open(`No tracking url exists`, 'OK', {
        duration: 5000,
      });
      return;
    }

    this.windowRef.openNewTab(trackUrl);
  }

  async rescheduleShipment(shipmentId: number) {
    const shipment: ShipmentRowFragment | null = await firstValueFrom(this.view.item(shipmentId));

    if (!shipment) return;

    const result = await firstValueFrom(
      this.shipmentDialogService
        .openShipmentFormDialog({
          shipmentId: shipment.id,
        })
        .afterClosed(),
    );

    if (!result) return;

    this.processShipment(
      shipmentId,
      (id) =>
        this.shipmentService.rescheduleShipment({
          id,
          methodId: result.methodId,
          slotId: result.slotId,
        }),
      'Reschedule Shipment',
    ).subscribe();
  }

  async updateShipmentSlot(shipmentId: number) {
    const orderId = await firstValueFrom(
      this.view.item(shipmentId).pipe(map((shipment) => shipment!.order.id)),
    );

    const result = await firstValueFrom(
      this.orderDialogService.openFulfilmentIntervalDialog({ orderId }).afterClosed(),
    );

    if (!result) return;

    if (result.interval?.slotAvailability?.slotId) {
      const slotId = result.interval.slotAvailability.slotId;
      this.processShipment(
        shipmentId,
        (id) =>
          this.shipmentService.requestSlot({
            id,
            slotId,
          }),
        'Assign Shipment Slot',
      ).subscribe();
    }
  }

  async updateShippingAddress(shipment: ShipmentRowObject) {
    const address = shipment.order.shippingAddress;
    const orderId = shipment.order.id;
    const shipmentId = shipment.id;

    const result = await firstValueFrom(
      this.addressDialogService
        .openAddressInputDialog({ value: address, title: 'Update Order Address' })
        .afterClosed(),
    );

    if (!result) return;

    this.view
      .mutateRow(shipmentId, (id) =>
        this.orderService.assignOrderAddress({
          assignment: AddressAssignment.Shipping,
          orderId,
          address: result.value,
        }),
      )
      .subscribe((result) => {
        if (result.isSuccessState()) {
          this.snackbar.open(`Order address updated`, 'OK', { duration: 5000 });
        }
      });
  }

  getParcelInfo(stat: any): string {
    const { width, height, length } = stat.dimensions;
    const weight = stat.weight;

    return `${weight} kg
    Length: ${length} cm
    Height: ${height} cm
    Width: ${width} cm
    `;
  }

  async openParcelDialog(shipmentId: number) {
    const result = await firstValueFrom(
      this.orderDialogService.openOrderCreateParcelDialog().afterClosed(),
    );

    if (!result) return;

    this.view
      .mutateRow(shipmentId, (id) =>
        this.shipmentService.createShipmentParcel({ shipmentId: Number(id), ...result }),
      )
      .subscribe((operation) => this.handleOperationResult(operation, 'Shipment parcel created'));
  }

  async editParcel(input: { row: any; parcel: any }) {
    const parcel = input.parcel;

    const result = await firstValueFrom(
      this.orderDialogService.openOrderUpdateParcelDialog(parcel).afterClosed(),
    );
    if (!result) return;

    this.view
      .mutateRow(input.row.id, () =>
        this.shipmentService.updateParcel({ id: parcel.id, ...result }),
      )
      .subscribe((operation: Operation<any, AnyOperationState<any>>) => {
        this.handleOperationResult(operation, 'Shipment parcel updated');
        console.log('operation', operation);
      });
  }

  // Bulk Actions //

  async cancelShipmentBulk() {
    const result = await firstValueFrom(
      this.orderDialogService.openOrderShipmentCancelConfirmation(),
    );
    if (!result) return;

    return this.processShipments(
      (id) =>
        this.shipmentService.transitionShipment({
          id,
          transition: ShipmentStateTransition.Cancel,
          sendNotification: false,
        }),
      'Cancel Shipments',
    ).subscribe();
  }

  async reconShipmentsBulk() {
    if (!this.view.selected) return;

    const shipments: ShipmentRowFragment[] = this.view.items.filter((i) =>
      this.view.selected.includes(i.id),
    );

    if (shipments.length === 0) return;

    const pendingAndShippedShipments = shipments.filter(
      (s) => s?.state === ShipmentState.Pending || s?.state === ShipmentState.InTransit,
    );

    if (pendingAndShippedShipments.length === 0) {
      this.snackbar.open(`All shipments must be in Pending or Shipped states`, 'OK', {
        duration: 5000,
      });
      return;
    }

    const result = await firstValueFrom(
      this.prompts.confirm({
        title: 'Recon Shipments',
        message: `Are you sure you want to recon the selected shipments?`,
      }),
    );
    if (!result) return;

    return this.processShipments(
      (id) => this.shipmentService.reconShipment({ id, sendNotification: false }),
      'Reconcile Shipments',
    ).subscribe();
  }

  submitWaybillBulk() {
    this.processShipments(
      (id) =>
        this.shipmentService.transitionShipment({
          id,
          transition: ShipmentStateTransition.Ready,
          sendNotification: false,
        }),
      'Submit Shipments Waybills',
    ).subscribe();
  }

  async shipShipmentBulk() {
    const result = await firstValueFrom(this.orderDialogService.openOrderShipConfirmation());
    if (!result) return;

    this.processShipments(
      (id) =>
        this.shipmentService.transitionShipment({
          id,
          transition: ShipmentStateTransition.Ship,
          sendNotification: result.sendNotification,
        }),
      'Ship Shipments',
    ).subscribe();
  }

  async deliverShipmentBulk() {
    const result = await firstValueFrom(this.orderDialogService.openOrderDeliverConfirmation());
    if (!result) return;

    return this.processShipments((id) =>
      this.shipmentService.transitionShipment({
        id,
        transition: ShipmentStateTransition.Deliver,
        sendNotification: false,
      }),
    ).subscribe();
  }

  async rescheduleBulk() {
    if (!this.view.selected) return;

    const shipments: ShipmentRowFragment[] = this.view.items.filter((i) =>
      this.view.selected.includes(i.id),
    );

    if (shipments.length === 0) return;

    const { slot } = shipments[0] || {};
    const methodMismatch = shipments.find((s) => s.slot?.method?.id !== slot?.method?.id);

    if (methodMismatch) {
      this.snackbar.open(`Not all selected orders are using the same shipping method`, 'OK', {
        duration: 5000,
      });
      return;
    }

    const data: ShipmentFormDialogData = {
      shipmentId: shipments[0].id,
      methodId: slot?.method?.id,
      slotId: slot?.id,
    };

    const result = await firstValueFrom(
      this.shipmentDialogService.openShipmentFormDialog(data).afterClosed(),
    );

    if (!result) return;

    this.processShipments((id) =>
      this.shipmentService.rescheduleShipment({
        id,
        methodId: result.methodId,
        slotId: result.slotId,
      }),
    ).subscribe();
  }

  async updateShipmentSlots() {
    const shipmentId = this.view.selected?.at(0);

    if (!shipmentId) return;

    const orderId = await firstValueFrom(
      this.view.item(shipmentId).pipe(map((shipment) => shipment!.order.id)),
    );

    const result = await firstValueFrom(
      this.orderDialogService.openFulfilmentIntervalDialog({ orderId }).afterClosed(),
    );

    if (!result) return;

    if (result.interval?.slotAvailability?.slotId) {
      const slotId = result.interval.slotAvailability.slotId;
      this.processShipments(
        (id) =>
          this.shipmentService.requestSlot({
            id,
            slotId,
          }),
        'Assign Shipment Slots',
      ).subscribe();
    }
  }

  // Bulk Print Actions //
  printManifestBulk() {
    this.promptDialogService
      .confirm({
        title: 'Print All Selected Shipments Orders',
        message: 'Printing them will mark the orders as printed.',
      })
      .subscribe((result) => {
        if (!result) return;
        this.view
          .mutateSelected((ids) => this.shipmentService.printOrderManifest({ ids }))
          .subscribe((state) => {
            if (state.isSuccessState()) {
              const url = state.data.url;
              this.snackbar
                .open(`Order manifest PDF is ready`, 'Open', { duration: 10000 })
                .onAction()
                .subscribe(() => this.windowRef.openNewTab(url));
            }
          });
      });
  }

  printManifestV2Bulk() {
    this.promptDialogService
      .confirm({
        title: 'Print All Selected Shipments Orders',
        message: 'Printing them will mark the orders as printed.',
      })
      .subscribe((result) => {
        if (!result) return;
        this.view
          .mutateSelected((ids) => this.shipmentService.printOrderManifestV2({ ids }))
          .subscribe((state) => {
            if (state.isSuccessState()) {
              const url = state.data.url;
              this.snackbar
                .open(`Order manifest PDF is ready`, 'Open', { duration: 10000 })
                .onAction()
                .subscribe(() => this.windowRef.openNewTab(url));
            }
          });
      });
  }

  printShipmentOrderGiftMessageBulk() {
    this.view
      .mutateSelected((ids) => this.shipmentService.printShipmentOrderGiftMessages({ ids }))
      .subscribe((state) => {
        if (state.isSuccessState()) {
          const url = state.data.url;
          this.snackbar
            .open(`Order gift message PDF is ready`, 'Open', { duration: 10000 })
            .onAction()
            .subscribe(() => this.windowRef.openNewTab(url));
        }
      });
  }

  printShipmentLabelsBulk() {
    this.view
      .mutateSelected((ids) => this.shipmentService.printShipmentLabels({ ids }))
      .subscribe((state) => {
        if (state.isSuccessState()) {
          const url = state.data.url;
          this.snackbar
            .open(`Shipment labels PDF is ready`, 'Open', { duration: 10000 })
            .onAction()
            .subscribe(() => this.windowRef.openNewTab(url));
        }
      });
  }

  async printRouteManifestBulk() {
    const result = await firstValueFrom(
      this.promptDialogService
        .autocomplete({
          provider: StockCategoryItemCollectionProvider,
          multiple: true,
          title: 'Filter categories?',
          message: 'Filter categories or export all categories.',
        })
        .afterClosed(),
    );

    if (result && result.assign) {
      this.view
        .mutateSelected((ids) =>
          this.shipmentService.printRouteManifest(
            { ids },
            {
              categoryIds: coerceArray(result.value).map((a) => Number(a)),
            },
          ),
        )
        .subscribe((state) => {
          if (state.isSuccessState()) {
            const url = state.data.url;
            this.snackbar
              .open(`Route manifest PDF is ready`, 'Open', { duration: 10000 })
              .onAction()
              .subscribe(() => this.windowRef.openNewTab(url));
          }
        });
    }
  }

  printRouteSummaryBulk() {
    this.view
      .mutateSelected((ids) => this.shipmentService.printRouteSummary({ ids }, {}))
      .subscribe((state) => {
        if (state.isSuccessState()) {
          const url = state.data.url;
          this.snackbar
            .open(`Route manifest PDF is ready`, 'Open', { duration: 10000 })
            .onAction()
            .subscribe(() => this.windowRef.openNewTab(url));
        }
      });
  }

  // Export Actions //
  exportCourierSpreadsheet(format?: string) {
    this.view
      .mutateSelected((ids) => {
        return this.shipmentService.printCourierSpreadsheet({ ids }, format);
      })
      .subscribe((state) => {
        if (state.isSuccessState()) {
          const url = state.data.url;

          this.snackbar
            .open(`Order Courier Spreadsheet is ready`, 'Download', { duration: 10000 })
            .onAction()
            .subscribe(() => this.windowRef.openNewTab(url));
        }
      });
  }

  packFleetBulk(format?: string) {
    this.view
      .mutateSelected((ids) => {
        return this.shipmentService.printPackfleetSpreadsheet({ ids }, format);
      })
      .subscribe((state) => {
        if (!state.isSuccessState()) return;
        const url = state.data.url;
        this.snackbar
          .open(`PackFleet export ready`, 'Download', { duration: 10000 })
          .onAction()
          .subscribe(() => this.windowRef.openNewTab(url));
      });
  }

  manifestBulk() {
    this.view
      .mutateSelected((ids) => {
        return this.shipmentService.printShipmentManifestSpreadsheet(ids);
      })
      .subscribe((state) => {
        if (!state.isSuccessState()) return;
        const url = state.data.url;
        this.snackbar
          .open(`Manifest ready`, 'Download', { duration: 10000 })
          .onAction()
          .subscribe(() => this.windowRef.openNewTab(url));
      });
  }

  royalMailBulk(shipment: ShipmentRowFragment, format?: string) {
    console.log('shipment', shipment);
    this.view
      .mutateSelected((ids) => this.shipmentService.printRoyalMailSpreadsheet({ ids }, format))
      .subscribe((state) => {
        if (state.isSuccessState()) {
          const url = state.data.url;

          this.snackbar
            .open(`Royal Mail CSV Export is ready`, 'Download', { duration: 10000 })
            .onAction()
            .subscribe(() => this.windowRef.openNewTab(url));
        }
      });
  }

  // Private
  private processShipment<T = any>(
    shipmentId: number,
    action: (shipmentId: number) => Observable<T>,
    label?: string,
  ) {
    return this.view.mutateRow(shipmentId, (shipmentId) => action(shipmentId), { label });
  }

  private processShipments<T = any>(action: (shipmentId: number) => Observable<T>, label?: string) {
    return this.view.mutateEachSelectedBatched((shipmentId) => action(shipmentId), { label });
  }

  private handleOperationResult(operation: Operation, successMessage?: string) {
    if (operation.isSuccessState() && successMessage) {
      this.snackbar.open(successMessage);
    }
    if (operation.isErrorState()) {
      this.snackbar.open(operation.state.error.message);
    }
  }
}
