import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ShipmentObject, ShipmentService } from '@x/ecommerce/domain-client';
import { ReturnShipmentUnitInput } from '@x/schemas/ecommerce';
import { of, switchMap } from 'rxjs';
import { IReturnShipmentDialogData } from '../components/return-shipment-dialog/return-shipment-dialog.component';
import { ShipmentDialogService } from './shipment-dialog.service';

@Injectable()
export class ShipmentActionsService {
  constructor(
    private readonly matDialog: MatDialog,
    private readonly shipmentDialogService: ShipmentDialogService,
    private readonly shipmentService: ShipmentService,
  ) {}

  editReturnShipment(shipment: ShipmentObject, options?: IReturnShipmentDialogData['options']) {
    return this.shipmentDialogService
      .openReturnShipmentFormDialog({
        orderId: shipment.order.id,
        id: shipment.id,
        methodId: shipment.method?.id ?? undefined,
        slotId: shipment.assignedSlotId ?? undefined,
        units: shipment.units.map(
          (u) =>
            ({
              orderItemId: u.item.id,
              quantity: u.quantity,
            }) satisfies ReturnShipmentUnitInput,
        ),
        options: {
          canSubmit: true,
          canEditUnits: true,
          ...options,
        },
      })
      .afterClosed()
      .pipe(
        switchMap((result) => {
          if (!result) return of(shipment);

          const saveOperation = this.shipmentService.updateReturnShipment({
            id: shipment.id,
            orderId: result.orderId,
            methodId: result.methodId,
            slotId: result.slotId,
            units: result.units,
          });
          if (result.action === 'submit') {
            return saveOperation.pipe(
              switchMap(() => this.shipmentService.initializeReturnShipment(shipment.id)),
            );
          } else {
            return saveOperation;
          }
        }),
      );
  }
}
