<ng-container *ngIf="view.data as shipment">
  <x-shipment-info-box
    class="m-4"
    [readonly]="readonly"
    [shipment]="view.data"
    (viewParcel)="openParcelDialog($event)"
    (removeParcel)="removeParcel($event)"
    (editParcel)="editParcel($event)"
    (recon)="recon.emit($event)"
    (submitWaybill)="submitWaybill.emit($event)"
    (ship)="ship.emit($event)"
    (deliver)="deliver.emit($event)"
    (cancel)="cancel.emit($event)"
    (fail)="fail.emit($event)"
    (reschedule)="reschedule.emit($event)"
    (recreate)="recreate.emit($event)"
    [showOrderInfo]="true"
  ></x-shipment-info-box>

  <mat-divider></mat-divider>
  <xd-expand-item icon="clock">
    <span xdHeadline>More Info</span>
    <table class="mat-typography info-table">
      <tbody>
        <tr *ngIf="shipment.createdAt">
          <th>Created At:</th>
          <td>{{ shipment.createdAt | datetime: 'short' }}</td>
        </tr>
        <tr *ngIf="shipment.updatedAt">
          <th>Updated At:</th>
          <td>{{ shipment.updatedAt | datetime: 'short' }}</td>
        </tr>
        <tr *ngIf="shipment.labeledAt">
          <th>Labeled At:</th>
          <td>{{ shipment.labeledAt | datetime: 'short' }}</td>
        </tr>
        <tr *ngIf="shipment.reservedAt">
          <th>Reserved At:</th>
          <td>{{ shipment.reservedAt | datetime: 'short' }}</td>
        </tr>
        <tr *ngIf="shipment.readyAt">
          <th>Ready At:</th>
          <td>{{ shipment.readyAt | datetime: 'short' }}</td>
        </tr>
        <tr *ngIf="shipment.shippedAt">
          <th>Shipped At:</th>
          <td>{{ shipment.shippedAt | datetime: 'short' }}</td>
        </tr>
        <tr *ngIf="shipment.completedAt">
          <th>Completed At:</th>
          <td>{{ shipment.completedAt | datetime: 'short' }}</td>
        </tr>
      </tbody>
    </table>
  </xd-expand-item>

  @if (shipment.order.shippingAddress?.instructions) {
    <mat-divider></mat-divider>
    <xd-expand-item icon="location-dot">
      <span xdHeadline>Address Instructions</span>
      <div>
        {{ shipment.order.shippingAddress?.instructions }}
      </div>
    </xd-expand-item>
  }

  @if (shipment.order.instructions) {
    <mat-divider></mat-divider>
    <xd-expand-item icon="memo">
      <span xdHeadline>Packing Instructions</span>
      <i>
        {{ shipment.order.instructions }}
      </i>
    </xd-expand-item>
  }

  @if (shipment.order.isGift) {
    <mat-divider></mat-divider>
    <xd-expand-item icon="gift">
      <span xdHeadline>Gift Message</span>
      <i>
        {{ shipment.order.giftMessage }}
      </i>
    </xd-expand-item>
  }

  <mat-divider></mat-divider>
</ng-container>
