import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ShipmentFormDialogComponent,
  ShipmentFormDialogData,
  ShipmentFormDialogResult,
} from '../components/shipment-form-dialog/shipment-form-dialog.component';

import {
  IReturnShipmentDialogData,
  IReturnShipmentDialogResult,
  ReturnShipmentDialogComponent,
} from '../components/return-shipment-dialog/return-shipment-dialog.component';

@Injectable()
export class ShipmentDialogService {
  constructor(private matDialog: MatDialog) {}

  openShipmentFormDialog(data: ShipmentFormDialogData) {
    return this.matDialog.open<
      ShipmentFormDialogComponent,
      ShipmentFormDialogData,
      ShipmentFormDialogResult
    >(ShipmentFormDialogComponent, { data, width: '600px' });
  }

  openReturnShipmentFormDialog(data: IReturnShipmentDialogData) {
    return this.matDialog.open<
      ReturnShipmentDialogComponent,
      IReturnShipmentDialogData,
      IReturnShipmentDialogResult
    >(ReturnShipmentDialogComponent, { data, width: '600px' });
  }
}
