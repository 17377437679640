import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormArray, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataViewFactoryService } from '@x/common/data';
import { OrderDialogService } from '@x/ecommerce-admin/app/orders/services/order-dialog.service';
import { IOrderDetailObject, ShippingMethodService } from '@x/ecommerce/domain-client';
import {
  OrderDetailProvider,
  ShippingMethodItemCollectionProvider,
  ShippingSlotItemCollectionProvider,
} from '@x/ecommerce/domain-data';
import { ReturnShipmentUnitInput } from '@x/schemas/ecommerce';
import { Subject, lastValueFrom, takeUntil } from 'rxjs';
import { DeepExtractTypeSkipArrays } from 'ts-deep-extract-types';

export interface IReturnShipmentDialogData {
  id?: number;
  orderId: number;
  methodId?: number;
  slotId?: string;
  units?: ReturnShipmentUnitInput[];
  options?: {
    canSubmit?: boolean;
    canEditUnits?: boolean;
  };
}

export interface IReturnShipmentDialogResult {
  action: 'save' | 'submit';
  id?: number;
  orderId: number;
  methodId: number;
  slotId?: string;
  units: ReturnShipmentUnitInput[];
}

@Component({
  selector: 'x-return-shipment-dialog',
  templateUrl: './return-shipment-dialog.component.html',
  styleUrl: './return-shipment-dialog.component.scss',
})
export class ReturnShipmentDialogComponent implements OnInit, OnDestroy {
  private readonly _destroy$ = new Subject<void>();

  Providers = {
    ShippingMethodItemCollectionProvider,
    ShippingSlotItemCollectionProvider,
    OrderDetailProvider,
  };

  orderView = this.dataViewFactory.resolveView(OrderDetailProvider);

  formGroup = new FormGroup({
    id: new FormControl(),
    orderId: new FormControl(),
    methodId: new FormControl(),
    slotId: new FormControl(),
    units: new UntypedFormArray([]),
  });

  slotRequired = false;
  methodId: number | null = null;

  constructor(
    private readonly dialog: MatDialogRef<
      ReturnShipmentDialogComponent,
      IReturnShipmentDialogResult
    >,
    @Inject(MAT_DIALOG_DATA)
    public readonly data: IReturnShipmentDialogData,
    private readonly dataViewFactory: DataViewFactoryService,
    private readonly shippingMethodService: ShippingMethodService,
    private readonly orderDialogs: OrderDialogService,
    private readonly changeRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.formGroup.patchValue({
      ...this.data,
    });

    this.orderView.setId(this.data.orderId);
    this.orderView.connect();

    this.methodId = this.formGroup.controls.methodId.value;
    this.fetchMethod();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  getOrCreateUnitGroup(item: DeepExtractTypeSkipArrays<IOrderDetailObject, ['items']>) {
    const unit = this.data.units?.find((u) => u.orderItemId === item.id);
    let ctrl = this.formGroup.controls.units.controls.find(
      (c) => (c as FormGroup).controls.orderItemId.value === item.id,
    );
    if (!ctrl) {
      ctrl = new FormGroup({
        orderItemId: new FormControl(item.id, [Validators.required]),
        quantity: new FormControl(unit?.quantity ?? 0, [Validators.required]),
      });
      this.formGroup.controls.units.push(ctrl);
    }
    return ctrl as FormGroup;
  }

  methodChanged() {
    this.updateSlotRequired(false);
    this.formGroup.patchValue({ slotId: null });
    this.fetchMethod();
  }

  fetchMethod() {
    this.methodId = this.formGroup.controls.methodId.value;
    if (!this.methodId) {
      this.slotRequired = false;
      return;
    }

    this.shippingMethodService
      .fetchItem(this.methodId)
      .pipe(takeUntil(this._destroy$))
      .subscribe((method) => {
        this.updateSlotRequired(method.slotRequired);
      });
  }

  updateSlotRequired(slotRequired: boolean) {
    this.slotRequired = slotRequired;

    if (this.slotRequired && !this.formGroup.controls.slotId.hasValidator(Validators.required)) {
      this.formGroup.controls.slotId.addValidators(Validators.required);
    } else if (
      !this.slotRequired &&
      this.formGroup.controls.slotId.hasValidator(Validators.required)
    ) {
      this.formGroup.controls.slotId.removeValidators(Validators.required);
    }

    this.formGroup.updateValueAndValidity();
    this.changeRef.markForCheck();

    console.log(this.formGroup);
  }

  async selectFulfilmentInterval() {
    const orderId = this.data.orderId;
    if (!orderId || !this.methodId) return;

    const result = await lastValueFrom(
      this.orderDialogs
        .openFulfilmentIntervalDialog({ orderId, shippingMethodId: this.methodId })
        .afterClosed(),
    );

    if (!result || !result.interval?.slotAvailability?.slotId) return;

    this.formGroup.patchValue({
      slotId: result.interval.slotAvailability.slotId,
    });
  }

  submit(action: 'save' | 'submit') {
    if (this.formGroup.invalid) return;
    const { orderId, methodId, slotId, units } = this.formGroup.value;

    this.dialog.close({
      action,
      orderId,
      methodId,
      slotId,
      units: units.filter((u: ReturnShipmentUnitInput) => u.quantity > 0),
    });
  }

  cancel() {
    this.dialog.close();
  }
}
