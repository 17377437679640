import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataViewFactoryService } from '@x/common/data';
import { DataChangeEvent } from '@x/dashboard/form/components/data-select/data-select.component';
import { IShippingMethodItemObject } from '@x/ecommerce/domain-client';
import {
  ShipmentDetailProvider,
  ShippingMethodItemCollectionProvider,
  ShippingSlotItemCollectionProvider,
} from '@x/ecommerce/domain-data';
import { lastValueFrom } from 'rxjs';
import { OrderDialogService } from '../../../orders/services/order-dialog.service';

export interface ShipmentFormDialogData {
  shipmentId: number | null;
  methodId?: number | null;
  slotId?: string | null;
}

export interface ShipmentFormDialogResult {
  shipmentId: number | null;
  methodId?: number | null;
  slotId?: string | null;
}

@Component({
  selector: 'x-shipment-form-dialog',
  templateUrl: 'shipment-form-dialog.component.html',
  styleUrls: ['shipment-form-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShipmentFormDialogComponent implements OnInit, OnDestroy {
  Providers = {
    ShippingMethodItemCollectionProvider,
    ShippingSlotItemCollectionProvider,
  };

  methodIdControl = new UntypedFormControl(null);
  slotIdControl = new UntypedFormControl(null);
  isRequestedControl = new UntypedFormControl(true);

  formGroup = new UntypedFormGroup({
    methodId: this.methodIdControl,
    slotId: this.slotIdControl,
  });

  shipmentView = this.dataViewFactory.resolveView(ShipmentDetailProvider, {
    id: this.data.shipmentId,
  });

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: ShipmentFormDialogData,
    private dialogRef: MatDialogRef<ShipmentFormDialogComponent, ShipmentFormDialogResult>,
    private orderDialogs: OrderDialogService,
    private dataViewFactory: DataViewFactoryService,
  ) {}

  ngOnInit() {
    this.shipmentView.dataChanges().subscribe((shipment) => {
      this.formGroup.patchValue({
        methodId: shipment?.method?.id,
        slotId: shipment?.slot?.id,
      });
    });
    this.shipmentView.connect();
  }
  ngOnDestroy() {
    this.shipmentView.disconnect();
  }

  submit() {
    this.formGroup.updateValueAndValidity();
    if (this.formGroup.invalid) return;

    this.dialogRef.close(this.formGroup.value);
  }

  async selectFulfilmentInterval() {
    const shippingMethodId = this.methodIdControl.value;

    const shipment = this.shipmentView.data;
    if (!shipment) return;

    const result = await lastValueFrom(
      this.orderDialogs
        .openFulfilmentIntervalDialog({ orderId: shipment.order.id, shippingMethodId })
        .afterClosed(),
    );

    if (!result || !result.interval?.slotAvailability?.slotId) return;

    this.slotIdControl.setValue(result.interval.slotAvailability.slotId);
  }

  onMethodChange(event: DataChangeEvent<IShippingMethodItemObject>) {
    this.slotIdControl.setValue(null);
  }
}
